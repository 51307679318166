import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import React, { useRef, useState } from "react"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { handleLogin, isLoggedIn, getUser } from "../../../services/auth"

import Layout from "../../../templates/Page"
import FilterResult from "../../ContentBuilder/FilterResult"
import MainDesignFilter from "../../../templates/Includes/MainDesignFilter"
import SidebarFilter from "../../../templates/Includes/SidebarFilter"

import SearchBar from "../../SearchBar"
import ContactUs from "../../ContactUs"
import Elements from "../../../templates/Elements/Elements"

const HomeLandList = () => {
  const [show, setShow] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setShow(false)
  })
  return (
    <Layout>
      <div className="container">
        <MainDesignFilter />
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section row py-5">
          <div className="col-lg-3">
            <SidebarFilter />
          </div>
          <AllHomeLands />
        </div>
      </div>
    </Layout>
  )
}

export default HomeLandList

function AllHomeLands() {
  const loadPage = () => {
    pageLimit = pageLimit + 6
    setPageLimit(pageLimit)
    if (pageLimit >= _total_items) {
      setHasMore(false)
    }
  }
  var [pageLimit, setPageLimit] = useState(6)
  var [hasMore, setHasMore] = useState(true)

  const allItems = useStaticQuery(graphql`
    {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
      allSilverStripeDataObject(
        filter: {
          className: { eq: "Undigital__Objects__HomeAndLand" }
          UndigitalHomeAndLand: { status: { eq: "Active" } }
        }
      ) {
        nodes {
          UndigitalHomeAndLand {
            urlSegment
            name
            address
            landPrice
            imagePath
            imagePosition
            landSize
            lotLength
            lotWidth
          }
        }
      }
    }
  `)
    const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  var checkURL = url.substring(0, url.length - 1)
  const segment = checkURL.substring(checkURL.lastIndexOf("/") + 1)
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  var sortBy = params.get("sort")
  var keyword = params.get("keyword")
  const unfiltered_items = allItems.allSilverStripeDataObject.nodes
  const _allData = []
  var i = 0
  for (; unfiltered_items[i]; ) {
    var _item = unfiltered_items[i].UndigitalHomeAndLand
    var name = _item.name
    var address = _item.address
    var landSize = _item.landSize + " (m2)"

    var lotWidth = _item.lotWidth
    var lotLength = _item.lotLength

    if (lotWidth && lotLength) {
      landSize += " | " + lotWidth + "m x " + lotLength + "m"
    }
    _item.formattedLandSize = landSize
    var match = true
    if (keyword) {
      keyword = keyword.toLowerCase()
      let _name = name.toLowerCase()
      let _address = address.toLowerCase()

      let matched_name = _name.search(keyword)
      let matched_address = _address.search(keyword)

      if (matched_name === -1 && matched_address === -1) {
        match = false
      }
    }
    var itemPrice = parseFloat(
      Math.round(_item.landPrice * 1000) / 1000
    ).toFixed(2)
    _item.formattedPrice =
      "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (match) {
      _allData.push(_item)
    }
    i++
  }
  _allData.sort((a, b) => {
    if (sortBy === "az") {
      return a.name > b.name ? 1 : -1
    } else if (sortBy === "latest") {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    } else if (sortBy === "pricehightolow") {
      return a.rrpPrice < b.rrpPrice ? 1 : -1
    } else if (sortBy === "pricelowtohigh") {
      return a.rrpPrice > b.rrpPrice ? 1 : -1
    } else {
      return 1
    }
  })

  const _total_items = _allData.length
  const currentData = _allData.slice(0, pageLimit)
  if (_total_items == currentData.length) {
    hasMore = false
  }
  return (
    <>
      <div className="col-lg-9 col-xl-8 offset-xl-1">
        <Row>
          {currentData.map(item => (
            <Col sm={6} key={item.urlSegment}>
              <div className="browser-home-cart">
                <h3 className="browser-home-title mb-0">
                  <Link to={`/explore/land/${item.urlSegment}/`}>
                    {item.name}
                  </Link>
                </h3>
                {item.address && (
                  <div className="browser-home-size  browser-border-bottom py-2">
                    {item.address}
                  </div>
                )}
                {item.formattedLandSize && (
                  <div className="browser-home-size  browser-border-bottom py-2">
                    {item.formattedLandSize}
                  </div>
                )}
                {globalConfig.hidePricingGlobally == 0 && (
                <div className="browser-border-bottom py-2">
                  Price: {item.formattedPrice}
                </div>
                )}
                <div className="pt-3 text-center">
                  <Link
                    to={`/explore/land/${item.urlSegment}/`}
                    className={`browser-home-img ${item.imagePosition}`}
                  >
                    {item.imagePath ? (
                      <div><img src={item.imagePath} alt={item.name} className="img-fluid" /></div>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {hasMore && (
          <div className="text-center">
            <button
              onClick={loadPage}
              className="btn btn-black btn-lg mt-5"
            >
              LOAD MORE
            </button>
          </div>
        )}
      </div>
    </>
  )
}
